import { AnimationEvent } from '@angular/animations';
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getModalLoadingState } from '../../../../store/ui/ui.selectors';
import { dynamicAnimations } from '../../../../shared/animations/animations';
import { Observable } from 'rxjs';
import { State } from '../../../../store';
import { DynamicLoaderService } from '../../../dynamic/services/dynamic-loader.service';

@Component({
  selector: 'shared-popup',
  templateUrl: './popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dynamicAnimations],
})
export class PopupComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('popupTemplate', { static: true })
  public popupTemplate?: TemplateRef<any>;

  @Input()
  set visible(val) {
    this._visible = val;
    this.visibleChange.emit(val);
  }
  get visible(): boolean {
    return this._visible;
  }

  @Input() outsideClick = false;
  @Input() panelClass = 'popup';
  @Input() animation: 'none' | 'fromRight' | 'scale' | 'exit' = 'none';

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() animationEnd = new EventEmitter<boolean>();
  @Output() animationStart = new EventEmitter<boolean>();

  customPopupTemplate: TemplateRef<any> | null = null;
  isLoading$?: Observable<boolean | undefined>;
  zIndex = 1000;

  _visible = false;

  portalHost?: DomPortalOutlet;
  popupAnimation = this.animation;

  activeClass = false;

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store<State>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    public dynamicLoaderService: DynamicLoaderService
  ) {}

  @HostListener('document:click', ['$event'])
  clickEvent(event: MouseEvent): void {
    if (this.outsideClick && this.visible) {
      const clickedOutside = (event.target as HTMLElement).classList.contains(
        'popup'
      );
      if (clickedOutside) {
        this.startExitAnimation();
      }
    }
  }

  removeComponentFromBody(): void {
    if (this.portalHost) {
      this.portalHost.detach();
    }
  }

  ngOnInit(): void {
    this.isLoading$ = this.store.pipe(select(getModalLoadingState));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['visible'];
    if (change && change.previousValue !== change.currentValue) {
      if (this.visible) {
        this.zIndex = 1000 + this.dynamicLoaderService.nextId();
        this.createDomElement();
      } else if (this.portalHost) {
        this.startExitAnimation();
      }
    }
  }

  ngOnDestroy(): void {
    this.dynamicLoaderService.previousId();
    this.removeComponentFromBody();
  }

  onAnimationDone(event: any): void {
    if (event.toState === 'exit') {
      this.removeComponentFromBody();
    }
    this.animationEnd.emit(true);
  }

  onAnimationStart(event: AnimationEvent): void {
    this.activeClass = event.toState !== 'exit';
    this.animationStart.emit(true);
  }

  startExitAnimation(): void {
    this.popupAnimation = 'exit';
    this.activeClass = false;
    this.visible = false;
    this.ref.markForCheck();
  }

  private createDomElement(): void {
    this.popupAnimation = this.animation;
    // Create a portalHost from a DOM element
    this.portalHost = new DomPortalOutlet(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    // Create a template portal
    if (!this.popupTemplate) {
      return;
    }
    const templatePortal = new TemplatePortal(
      this.popupTemplate,
      this.viewContainerRef
    );

    // Attach portal to host
    this.portalHost.attach(templatePortal);
  }
}
