import { Directive, ElementRef, TemplateRef } from '@angular/core';

import { PopupComponent } from '../components/popup/popup.component';

@Directive({
  selector: 'appPopupTemplate, [appPopupTemplate]',
})
export class PopupDirective {
  constructor(
    private cmPopupComponent: PopupComponent,
    public elementRef: ElementRef,
    private templateRef: TemplateRef<any>
  ) {
    this.cmPopupComponent.customPopupTemplate = this.templateRef;
  }
}
