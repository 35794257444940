import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import {
  ModalConfiguration,
  newModalConfiguration,
} from '../../models/modal.model';
import { DynamicLoaderService } from '../../services/dynamic-loader.service';
import { State } from '../../../../store';
import { dynamicAnimations } from '../../../animations/animations';
import { getModalLoadingState } from '../../../../store/ui/ui.selectors';

@Component({
  selector: 'dynamic-dynamic-container',
  templateUrl: './dynamic-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dynamicAnimations],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'modalConfiguration.panelClass',
  },
})
export class DynamicContainerComponent<C extends {}>
  implements OnInit, OnDestroy
{
  @ViewChild('container', { read: CdkPortalOutlet, static: false })
  container?: CdkPortalOutlet;

  dynamicLoaderService?: DynamicLoaderService;
  containerId = 0;
  componentRef?: ComponentRef<C>;
  animationEnd = new Subject<boolean>();
  isLoading$?: Observable<boolean | undefined>;

  activeClass = false;

  modalConfiguration: ModalConfiguration = newModalConfiguration();

  constructor(
    private store: Store<State>,
    public elementRef: ElementRef,
    private ref: ChangeDetectorRef
  ) {}

  @HostBinding('class.active') get a_class(): boolean {
    return this.activeClass;
  }

  @HostListener('document:click', ['$event'])
  clickEvent(event: any): void {
    if (this.modalConfiguration.outsideClick) {
      const clickedOutside = event.target === this.elementRef.nativeElement;
      if (clickedOutside) {
        this.close();
      }
    }
  }

  ngOnInit(): void {
    this.isLoading$ = this.store.pipe(select(getModalLoadingState));
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.dynamicLoaderService?.previousId();
      this.componentRef.destroy();
    }
  }

  onCloseClick(): void {
    this.close();
  }

  attachChildComponent(childComponent: any, data: any): void {
    this.ref.detectChanges();
    if (!this.container) {
      return;
    }
    if (this.container.hasAttached()) {
      return;
    }
    this.componentRef = this.container.attachComponentPortal(childComponent);
    Object.assign(this.componentRef.instance, data);
  }

  close(): void {
    this.startExitAnimation();
  }

  removeContainer(): void {
    if (!isNaN(this.containerId)) {
      this.dynamicLoaderService?.removeComponentFromBody(this.containerId);
    }
  }

  onAnimationDone(event: any): void {
    if (event.toState === 'exit') {
      this.removeContainer();
    }
    this.animationEnd.next(true);
  }

  onAnimationStart(event: any): void {
    this.activeClass = event.toState !== 'exit';
  }

  startExitAnimation(): void {
    this.modalConfiguration.animation = 'exit';
    this.activeClass = false;
    this.ref.markForCheck();
  }
}
