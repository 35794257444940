import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProcessingPopupModel } from '../../shared/dialogs/services/processing-popup.model';
import { MediaConfiguration } from '../../shared/layout/models/media-configuration';
import { WindowSizeModel } from '../../shared/layout/services/window-size.model';

import { UiState } from './ui.reducer';

export const uiStateSelector = createFeatureSelector<UiState>('ui');

export const getUserPhotoUrl = createSelector(
  uiStateSelector,
  (state: UiState): string | undefined => state.photoUrl
);
export const getWindowSizeChanged = createSelector(
  uiStateSelector,
  (state: UiState): WindowSizeModel | undefined => state.windowSize
);
export const getSidebarOpenState = createSelector(
  uiStateSelector,
  (state: UiState): boolean | undefined => state.isSBarOpen
);
export const getSidebarLoadingState = createSelector(
  uiStateSelector,
  (state: UiState): boolean | undefined => state.isSidebarLoading
);
export const getContentLoadingState = createSelector(
  uiStateSelector,
  (state: UiState): boolean | undefined => state.isContentLoading
);
export const getModalLoadingState = createSelector(
  uiStateSelector,
  (state: UiState): boolean | undefined => state.isModalLoading
);
export const getProcessingPopup = createSelector(
  uiStateSelector,
  (state: UiState): ProcessingPopupModel | undefined => state.processingData
);
export const getUserIdle = createSelector(
  uiStateSelector,
  (state: UiState): boolean | undefined => state.isUserIdle
);
export const getNewUpdateAvailable = createSelector(
  uiStateSelector,
  (state: UiState): boolean => state.newUpdateAvailable
);
export const getMediaConfiguration = createSelector(
  uiStateSelector,
  (state: UiState): MediaConfiguration => state.mediaConfig
);
