import { EventEmitter } from '@angular/core';

export interface Modal {
  closeClick: EventEmitter<void>;
}

export interface ModalConfiguration {
  /** apparence */
  panelClass:
    | 'popup'
    | 'popup full'
    | 'popup medium'
    | 'popup medium800'
    | 'popup small'
    | 'popup third';
  animation: 'none' | 'fromRight' | 'scale' | 'exit';
  outsideClick: boolean;

  /** Height and Width of the dialog. */
  width?: string;
  height?: string;

  /** Min-width of the dialog. If a number is provided, pixel units are assumed. */
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
}

export function newModalConfiguration(config?: Partial<ModalConfiguration>): ModalConfiguration {
  return {
    panelClass: 'popup full',
    animation: 'none',
    outsideClick: false,
    width: '0px',
    height: '0px',
    minWidth: '0px',
    minHeight: '0px',
    maxWidth: '0px',
    maxHeight: '0px',
    ...config,
  };
}
