<ng-template #popupTemplate>
  <section
    [ngStyle]="{ 'z-index': zIndex }"
    [class]="panelClass"
    [class.active]="activeClass"
    [ngClass]="{ 'loader-overlay loading': isLoading$ | async }">
    <div
      class="popup-modal"
      [@openDynamicComponent]="popupAnimation"
      (@openDynamicComponent.start)="onAnimationStart($event)"
      (@openDynamicComponent.done)="onAnimationDone($event)">
      <ng-content *ngIf="!customPopupTemplate"></ng-content>
      <ng-container *ngTemplateOutlet="customPopupTemplate"></ng-container>
    </div>
  </section>
</ng-template>
