import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxButtonModule } from 'devextreme-angular';

import { PopupComponent } from './components/popup/popup.component';
import { PopupDirective } from './directives/popup.directive';

@NgModule({
  declarations: [PopupDirective, PopupComponent],
  imports: [CommonModule, DxButtonModule],
  exports: [PopupDirective, PopupComponent],
})
export class SharedPopupModule {}
