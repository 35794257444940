import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const dynamicAnimations = [
  trigger('openDynamicComponent', [
    state('scale', style({ transform: 'scale(1)' })),
    state('fromRight', style({ transform: 'translateX(0)' })),
    transition('scale => exit', [
      style({ transform: 'scale(1)' }),
      animate(200, style({ transform: 'scale(0)' })),
    ]),
    transition('void => scale', [
      style({ transform: 'scale(0)' }),
      animate(200, style({ transform: 'scale(1)' })),
    ]),
    transition('fromRight => exit', [
      style({ transform: 'translateX(0)' }),
      animate(300, style({ transform: 'translateX(100%)' })),
    ]),
    transition('void => fromRight', [
      style({ transform: 'translateX(100%)' }),
      animate(300, style({ transform: 'translateX(0)' })),
    ]),
  ]),
];
