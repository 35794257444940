import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal';
import {
  ApplicationRef,
  Compiler,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  InjectionToken,
  Injector,
  NgModuleFactory,
  OnDestroy,
  Type,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DynamicContainerComponent } from '../components/dynamic-container/dynamic-container.component';
import { ModalConfiguration } from '../models/modal.model';
import { modules } from '../models/module.model';

export const DYNAMIC_COMPONENT = new InjectionToken<any>('DYNAMIC_COMPONENT');

@Injectable({
  providedIn: 'root',
})
export class DynamicLoaderService implements OnDestroy {
  private containerId = 0;
  private registry: { [key: string]: ComponentRef<any> } = {};
  private destroyed: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private compiler: Compiler,
    private appRef: ApplicationRef
  ) {
    this.subscribeToNavigationStart();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  public previousId(): number {
    this.containerId--;
    return this.containerId;
  }

  public nextId(): number {
    this.containerId++;
    return this.containerId;
  }

  open<C extends {}>(
    moduleOrComp: ComponentType<C> | string,
    config: ModalConfiguration,
    data: any
  ): Promise<DynamicContainerComponent<C>> {
    const portalHost = new DomPortalOutlet(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
    if (this.isComponent<C>(moduleOrComp)) {
      return this.instantiateComponent<C>(
        moduleOrComp as ComponentType<C>,
        portalHost,
        this.injector,
        config,
        data
      );
    } else {
      return this.instantiateLazyComponent<C>(
        moduleOrComp as string,
        portalHost,
        this.injector,
        config,
        data
      );
    }
  }

  instantiateLazyComponent<C>(
    path: string,
    portalHost: DomPortalOutlet,
    injector: Injector,
    config: ModalConfiguration,
    data: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const module = modules.find(r => r.path === path);
      if (!module) {
        throw new Error(
          `Unrecognized module "${path}". Make sure it is registered in the component registry`
        );
      }
      module
        .loadChildren()
        .then(moduleOrFactory => this.getCompiledModule(moduleOrFactory))
        .then(moduleFactory => {
          const containerComponent = this.createContainerComponent<C>(
            moduleFactory,
            portalHost,
            injector,
            config,
            data
          );
          resolve(containerComponent.instance);
        })
        .catch(err => {
          console.error('error loading module', err);
          reject(err);
        });
    });
  }

  removeComponentFromBody(refId: number): void {
    if (this.registry[refId]) {
      this.appRef.detachView(this.registry[refId].hostView);
      this.registry[refId].destroy();
      delete this.registry[refId];
    } else {
      console.warn(`Container with id '${refId}' doesn't exist in the registry`);
    }
  }

  removeAllDynamicComponents(): void {
    Object.entries(this.registry).forEach(([key, value]) => {
      this.appRef.detachView(value.hostView);
      value.destroy();
      delete this.registry[key];
    });
  }

  private subscribeToNavigationStart(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationStart),
        filter((event: NavigationStart) => event.navigationTrigger === 'popstate'),
        takeUntil(this.destroyed)
      )
      .subscribe(() => {
        this.removeAllDynamicComponents();
      });
  }

  private isComponent<C>(moduleOrComp: ComponentType<C> | string): boolean {
    return moduleOrComp instanceof Type;
  }

  private instantiateComponent<C>(
    moduleOrComp: ComponentType<C>,
    portalHost: DomPortalOutlet,
    injector: Injector,
    config: ModalConfiguration,
    data: any
  ): Promise<any> {
    return new Promise(resolve => {
      const containerRef = this.createDomElement<C>(
        moduleOrComp,
        undefined,
        portalHost,
        injector,
        config,
        data
      );
      resolve(containerRef.instance);
    });
  }

  private getCompiledModule(
    moduleOrFactory: NgModuleFactory<any> | Type<any>
  ): Promise<NgModuleFactory<any>> {
    if (moduleOrFactory instanceof NgModuleFactory) {
      return new Promise(resolve => resolve(moduleOrFactory));
    } else {
      return this.compiler.compileModuleAsync(moduleOrFactory);
    }
  }

  private createContainerComponent<C>(
    moduleFactory: NgModuleFactory<any>,
    portalHost: DomPortalOutlet,
    injector: Injector,
    config: ModalConfiguration,
    data: any
  ): ComponentRef<C> {
    const moduleRef = moduleFactory.create(injector);
    const dynamicComponentType = moduleRef.injector.get(DYNAMIC_COMPONENT);
    const componentResolver = moduleRef.componentFactoryResolver;
    return this.createDomElement<C>(
      dynamicComponentType,
      componentResolver,
      portalHost,
      injector,
      config,
      data
    );
  }

  private createDomElement<C>(
    dynamicComponentType: ComponentType<C>,
    componentFactoryResolver: ComponentFactoryResolver | undefined,
    portalHost: DomPortalOutlet,
    injector: Injector,
    config: ModalConfiguration,
    data: any
  ): ComponentRef<any> {
    const containerId = this.nextId();

    const modalComponent = new ComponentPortal(DynamicContainerComponent, undefined, injector);
    const modalComponentRef = portalHost.attach(modalComponent);
    const modalInstance = modalComponentRef.instance;

    modalInstance.modalConfiguration = config;
    modalInstance.elementRef.nativeElement.style.zIndex = 1000 + containerId;

    this.registry[this.containerId] = modalComponentRef;

    modalInstance.dynamicLoaderService = this;
    modalInstance.containerId = containerId;

    const childComponent = new ComponentPortal(
      dynamicComponentType,
      undefined,
      injector,
      componentFactoryResolver
    );

    modalInstance.attachChildComponent(childComponent, data);

    return modalComponentRef;
  }
}
